import axios from "axios";

// const baseURL = process.env.REACT_APP_BACKEND_URL;
// const baseURL = "https://api.makemyhouse.com/";
// const mmhBaseURL = "https://makemyhouse.com/";
 const mmhBaseURL = "https://testing.makemyhouse.com/"
 const baseURL = "https://api-uat.makemyhouse.com/";

let headers = {
  "content-type": "application/json",
  Accept: "application/x-www-form-urlencoded",
  "x-api-key": `${localStorage.getItem("token")}`,
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

const trelloInstance = axios.create({
  baseURL: "https://api.trello.com/1/",
});

export { axiosInstance, baseURL, mmhBaseURL, trelloInstance };
